import '../scss/app.scss'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'

import { each } from 'lodash'

import Homepage from './pages/Homepage'

// import Scroll from './components/Scroll'
// import Cursor from './components/Cursor'
import Navbar from './components/Navbar'

class App {
  constructor () {
    this.createContent()
    this.createPages()
    this.createNavbar()
    this.addLinkListeners()
    this.addEventListeners()
    this.createScroll()
    // this.createCursor()
  }

  createContent () {
    this.content = document.querySelector('.content')
    this.template = this.content.getAttribute('data-template')
  }

  // createCursor () {
  //   this.cursor = new Cursor({ lerp: 1 })

  //   window.cursor = this.cursor
  // }

  createPages () {
    // TODO: criar animações para quando transicionar pra 404
    this.pages = {
      homepage: new Homepage()
    }

    this.page = this.pages[this.template]

    this.page.create()
    this.page.show()
  }

  createNavbar () {
    this.navbar = new Navbar()

    window.navbar = this.navbar
  }

  createScroll () {
    // this.scroll = new Scroll()

    // window.scroll = this.scroll

    this.lenis = new Lenis({
      wrapper: window,
      content: document.documentElement,
      lerp: 0.1,
      orientation: 'vertical',
      smoothWheel: true,
      smoothTouch: false,
      wheelMultiplier: 1,
      touchMultiplier: 1,
      normalizeWheel: true,
      infinite: false
    })

    this.lenis.on('scroll', (e) => {
      ScrollTrigger.update()
    })

    gsap.ticker.add((time) => {
      this.lenis.raf(time * 1000)
    })

    const raf = (time) => {
      this.lenis.raf(time)
      window.requestAnimationFrame(raf)
    }

    window.requestAnimationFrame(raf)
  }

  onPopState () {
    this.onChange({
      url: window.location.pathname,
      push: false
    })
  }

  async onChange ({ url, push = true }) {
    await this.page.hide()
    const request = await window.fetch(url)

    if (request.status === 200) {
      const nextPageHtml = await request.text()

      if (push) {
        window.history.pushState({}, '', url)
      }

      const fakeDiv = document.createElement('div')
      fakeDiv.innerHTML = nextPageHtml

      const content = fakeDiv.querySelector('.content')

      this.template = content.getAttribute('data-template')
      this.content.setAttribute('data-template', this.template)
      this.content.innerHTML = content.innerHTML

      this.page = this.pages[this.template]

      this.page.create()
      this.page.show()

      // this.cursor.addEventListeners()

      this.navbar.create()
      this.navbar.addEventListeners()

      this.addLinkListeners()
    }
  }

  addLinkListeners () {
    // TODO: colocar if pra não transicionar se o href for pra mesma página, email, tel, etc
    const links = document.querySelectorAll('a')

    each(links, link => {
      link.onclick = event => {
        if (event.target.target !== '_blank') {
          event.preventDefault()

          const { href } = event.target

          this.onChange({ url: href })
        }
      }
    })
  }

  addEventListeners () {
    window.addEventListener('popstate', this.onPopState.bind(this))
  }
}

new App()
