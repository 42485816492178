import { each } from 'lodash'
import gsap from 'gsap'
import { mapEach } from '../utils/dom'
import Parallax from '../components/Parallax'
export default class Page {
  constructor ({ id, element, elements }) {
    this.id = id
    this.selector = element
    this.selectors = {
      element,
      elements: {
        ...elements
      }
    }
  }

  create () {
    this.element = document.querySelector(this.selectors.element)
    this.elements = {}

    each(this.selectors.elements, (selector, key) => {
      if (selector instanceof window.HTMLElement || selector instanceof window.NodeList || Array.isArray(selector)) {
        this.elements[key] = selector
      } else {
        this.elements[key] = document.querySelectorAll(selector)

        if (this.elements[key].length === 0) {
          this.elements[key] = null
        } else if (this.elements[key].length === 1) {
          this.elements[key] = document.querySelector(selector)
        }
      }
    })

    this.createAnimations()
  }

  createAnimations () {
    this.selectors.elements.animationsParallax = document.querySelectorAll('main [data-parallax]')

    this.animationsParallax = mapEach(this.selectors.elements.animationsParallax, element => {
      return new Parallax({
        element,
        speed: element.getAttribute('data-parallax')
      })
    })
  }

  show () {
    return new Promise(resolve => {
      gsap.fromTo(
        this.selectors.element,
        {
          autoAlpha: 0
        },
        {
          autoAlpha: 1,
          onComplete: resolve
        }
      )
    })
  }

  hide () {
    window.navbar.removeEventListeners()

    return new Promise(resolve => {
      gsap.to(this.selectors.element, {
        autoAlpha: 0,
        onComplete: resolve
      })
    })
  }
}
