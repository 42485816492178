import Component from '../classes/Component'

export default class Parallax extends Component {
  constructor ({ element, speed = 1 }) {
    super({
      element
    })

    this.speed = speed
    this.handleScroll()
  }

  handleScroll () {
    const yOffset = this.element.getBoundingClientRect().y
    const parallaxValue = yOffset * this.speed
    this.element.style.transform = `translateY(${parallaxValue * 0.04}%)`
  }

  addEventListeners () {
    super.addEventListeners()

    window.addEventListener('scroll', this.handleScroll.bind(this))
  }

  removeEventListeners () {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
  }
}
