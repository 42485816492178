import Component from '../classes/Component'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default class Navbar extends Component {
  preloader = window.preloader

  constructor () {
    super({
      element: '.navbar',
      elements: {
        wrapper: '.navbar__wrapper'
      }
    })
  }
}
