import Page from '../classes/Page'
import Swiper from 'swiper'
export default class Homepage extends Page {
  constructor () {
    super({
      id: 'homepage',
      element: '.homepage',
      elements: {
        slide: '.homepage__jobs__slides'
      }
    })
  }

  create () {
    super.create()

    this.createSlides()
  }

  createSlides () {
    new Swiper(this.elements.slide, {
      spaceBetween: 16,

      breakpoints: {
        768: {
          spaceBetween: 48
        }
      }
    })
  }
}
